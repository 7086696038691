import 'src/initAuth'

import {
  ExpandedFirebaseDataDoc,
  FirebaseDataDoc,
  PublishStatus,
} from '@arcadehq/shared/types'
import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from 'firebase/app-check'
import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  FieldPath,
  Firestore,
  getDoc,
  getDocFromServer,
  getDocs,
  getFirestore,
  initializeFirestore,
  limit,
  onSnapshot,
  orderBy,
  OrderByDirection,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  startAfter,
  Timestamp,
  updateDoc,
  where,
  WhereFilterOp,
} from 'firebase/firestore'
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
  HttpsCallableOptions,
} from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import NProgress from 'nprogress'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isPreviewEnv, isProductionEnv } from 'src/helpers'
import { ModelDefaults } from 'src/types'
import { captureError } from 'src/utils/exceptions'
import { getDateFromFirebaseTimestamp } from 'src/utils/firebase-timestamp'

import {
  FirebaseConfig,
  RECAPTCHA_SITE_KEY,
  USE_FIREBASE_EMULATOR,
} from '../../constants'

export { Timestamp }

let app: FirebaseApp | undefined
if (!app) app = initializeApp(FirebaseConfig)

let appCheck: AppCheck | undefined
if (
  app &&
  !appCheck &&
  typeof document !== 'undefined' &&
  !USE_FIREBASE_EMULATOR &&
  (isProductionEnv() || isPreviewEnv())
) {
  try {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
    })
  } catch (error) {
    captureError(error)
  }
}

let db: Firestore | undefined
export function firestore() {
  if (!db) {
    db = app
      ? // @ts-ignore
        initializeFirestore(app, {
          // If we set props on a document to undefined, we get Firestore SDK errors
          ignoreUndefinedProperties: true,
          // Some of our customers are using network filtering tools that block websockets/webchannels
          // In these cases, the SDK will fall back to long polling, which is not ideal, but better than
          // not working at all.
          // https://github.com/firebase/firebase-js-sdk/issues/1674
          experimentalAutoDetectLongPolling: true,
        })
      : getFirestore()
    if (USE_FIREBASE_EMULATOR) connectFirestoreEmulator(db, 'localhost', 8080)
  }
  return db
}

export function firestorage() {
  const storage = getStorage()
  if (USE_FIREBASE_EMULATOR) {
    connectStorageEmulator(storage, 'localhost', 9199)
  }
  return storage
}

export function getCallableFunction<Req, Res>(
  name: string,
  opts?: HttpsCallableOptions
) {
  const functions = getFunctions()
  if (USE_FIREBASE_EMULATOR) {
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  }
  return httpsCallable<Req, Res>(functions, name, opts)
}

export function useFirebaseCollectionIds(collectionName: string) {
  const [{ ids, loading }, setIdsAndLoading] = useState<{
    ids: string[]
    loading: boolean
  }>({ ids: [], loading: true })

  useEffect(() => {
    return onSnapshot(
      collection(firestore(), collectionName),
      function onCollectionSnapshot(querySnapshot) {
        const newIds: string[] = []
        querySnapshot.forEach(doc => {
          newIds.push(doc.id)
        })
        if (ids.join(',') !== newIds.join(',')) {
          setIdsAndLoading({ ids: newIds, loading: false })
        }
      }
    )
  }, [collectionName, ids])

  return { ids, loading }
}

export function useFirebaseCollectionIdsOnce(collectionName: string) {
  const [{ ids, loading }, setIdsAndLoading] = useState<{
    ids: string[]
    loading: boolean
  }>({ ids: [], loading: true })

  useEffect(() => {
    getDocs(collection(firestore(), collectionName)).then(docs => {
      const newIds: string[] = []
      docs.forEach(doc => {
        newIds.push(doc.id)
      })
      if (ids.join(',') !== newIds.join(',')) {
        setIdsAndLoading({ ids: newIds, loading: false })
      }
    })
  }, [collectionName, ids])

  return { ids, loading }
}

type Opts = {
  where?: [string | FieldPath, WhereFilterOp, any][]
  limit?: number
  startAfter?: QueryDocumentSnapshot<DocumentData>
  orderBy?: {
    fieldPath: string | FieldPath
    directionStr?: OrderByDirection
  }[]
}

export function useFirebaseCollectionCount(
  collectionName: string,
  whereComponents?: [string | FieldPath, WhereFilterOp, any][],
  skip: boolean = false
) {
  const [{ count, loading }, setCountAndLoading] = useState<{
    count: number | undefined
    loading: boolean
  }>({ count: undefined, loading: true })

  const queryParams: QueryConstraint[] = useMemo(() => {
    if (whereComponents) {
      return whereComponents.map(([fieldPath, op, value]) =>
        where(fieldPath, op, value)
      )
    }
    return []
  }, [whereComponents])

  useEffect(() => {
    if (skip) return
    return onSnapshot(
      query(collection(firestore(), collectionName), ...queryParams),
      function onCollectionSnapshot(querySnapshot) {
        setCountAndLoading({ count: querySnapshot.size, loading: false })
      }
    )
  }, [collectionName, queryParams, skip])

  return { count, loading }
}

export function useFirebaseCollection<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>,
  opts?: Opts,
  skip?: boolean
) {
  const [{ entities, loading, lastVisible, hasMore }, setData] = useState<{
    entities: Entity[]
    loading: boolean
    lastVisible: QueryDocumentSnapshot<DocumentData> | undefined
    hasMore: boolean
  }>({
    entities: [],
    loading: true,
    lastVisible: undefined,
    hasMore: false,
  })

  const extractEntities = useCallback(
    (querySnapshot: QuerySnapshot<DocumentData>) => {
      const newEntities: Entity[] = []
      querySnapshot.forEach(doc => {
        try {
          const entity = getEntityFromDoc<Entity, EntityData, EntityDoc>(
            collectionName,
            doc,
            transformer,
            updateTransformer
          )
          if (entity) {
            newEntities.push(entity)
          }
        } catch (err) {
          captureError(err)
        }
      })
      return newEntities
    },
    [collectionName, transformer, updateTransformer]
  )

  useEffect(() => {
    if (skip) {
      return
    }
    const q = query(
      collection(firestore(), collectionName),
      ...constructQueryParams(opts)
    )

    if (loading === false) {
      setData({
        entities,
        lastVisible,
        hasMore,
        loading: true,
      })
    }

    return onSnapshot(q, function onCollectionSnapshot(querySnapshot) {
      const newEntities = extractEntities(querySnapshot)
      setData({
        entities: newEntities,
        loading: false,
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore: querySnapshot.docs.length > 0,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, JSON.stringify(opts)])

  const constructQueryParams = useCallback((opts?: Opts) => {
    const queryParams: QueryConstraint[] = [limit(opts?.limit || 500)]

    opts?.where?.forEach(clause => {
      queryParams.push(where(...clause))
    })
    if (opts?.orderBy) {
      opts.orderBy.forEach(orderByData => {
        queryParams.push(
          orderBy(orderByData.fieldPath, orderByData.directionStr)
        )
      })
    }
    if (opts?.startAfter) {
      queryParams.push(startAfter(opts.startAfter))
    }
    return queryParams
  }, [])

  const getNextEntities = useCallback(() => {
    if (!hasMore) return
    const q = query(
      collection(firestore(), collectionName),
      ...constructQueryParams({ ...opts, startAfter: lastVisible, limit: 4 })
    )
    NProgress.start()
    return getDocs(q).then(function onEntitySnapshot(querySnapshot) {
      const newEntities = extractEntities(querySnapshot)
      setData({
        entities: entities.concat(newEntities),
        loading: false,
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore: querySnapshot.docs.length > 0,
      })
      NProgress.done()
    })
  }, [
    collectionName,
    constructQueryParams,
    entities,
    extractEntities,
    hasMore,
    lastVisible,
    opts,
  ])

  return { entities, getNextEntities, hasMore, loading }
}

export function useFirebaseCollectionOnce<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>,
  userId: string,
  opts?: Opts
) {
  const [{ entities, loading }, setEntitiesAndLoading] = useState<{
    entities: Entity[]
    loading: boolean
  }>({ entities: [], loading: true })

  const extractEntities = useCallback(
    (querySnapshot: QuerySnapshot<DocumentData>) => {
      const newEntities: Entity[] = []
      querySnapshot.forEach(doc => {
        try {
          const entity = getEntityFromDoc<Entity, EntityData, EntityDoc>(
            collectionName,
            doc,
            transformer,
            updateTransformer
          )
          if (entity) {
            newEntities.push(entity)
          }
        } catch (err) {
          captureError(err)
        }
      })

      return newEntities
    },
    [collectionName, transformer, updateTransformer]
  )

  useEffect(() => {
    if (collectionName === 'flows') {
      Promise.all([
        new Promise(resolve =>
          getDocs(
            query(
              collection(firestore(), collectionName),
              where('createdBy', '==', userId),
              where('status', 'in', [
                PublishStatus.draft,
                PublishStatus.published,
              ]),
              orderBy('modified', 'desc')
            )
          ).then(querySnapshot => resolve(extractEntities(querySnapshot)))
        ),
        new Promise(resolve =>
          onSnapshot(
            query(
              collection(firestore(), collectionName),
              where('editors', 'array-contains', userId),
              where('status', 'in', [
                PublishStatus.draft,
                PublishStatus.published,
              ]),
              orderBy('modified', 'desc')
            ),
            function onSnapshot(querySnapshot) {
              resolve(extractEntities(querySnapshot))
            }
          )
        ),
      ]).then(([ownedEntities, editRightEntities]) => {
        const uniqEntities = new Map()
        ;[
          ...(ownedEntities as Entity[]),
          ...(editRightEntities as Entity[]),
        ].forEach(entity => {
          uniqEntities.set(entity.id, entity)
        })
        setEntitiesAndLoading({
          entities: Array.from(uniqEntities.values()).sort((a, b) =>
            a.modified < b.modified ? 1 : -1
          ),
          loading: false,
        })
      })
    } else {
      const queryParams: QueryConstraint[] = [where('createdBy', '==', userId)]

      if (opts?.orderBy) {
        opts.orderBy.forEach(orderByData => {
          queryParams.push(
            orderBy(orderByData.fieldPath, orderByData.directionStr)
          )
        })
      }
      if (opts?.limit) {
        queryParams.push(limit(opts.limit))
      }
      if (opts?.startAfter) {
        queryParams.push(startAfter(opts.startAfter))
      }

      const q = query(collection(firestore(), collectionName), ...queryParams)
      getDocs(q).then(querySnapshot => {
        const newEntities = extractEntities(querySnapshot)
        setEntitiesAndLoading({
          entities: newEntities,
          loading: false,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { entities, loading }
}

export function useFirebaseEntity<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  id: string | undefined,
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>,
  waitForDocWithId = false,
  skip: boolean = false
) {
  const [{ entity, loading }, setEntityAndLoading] = useState<{
    entity: Entity | undefined
    loading: boolean
  }>({ entity: undefined, loading: true })

  useEffect(() => {
    if (skip) {
      return
    }
    if (!id) {
      setEntityAndLoading({ entity: undefined, loading: waitForDocWithId })
      return
    }
    return onSnapshot(
      doc(firestore(), collectionName, id),
      function onEntitySnapshot(doc) {
        try {
          const newEntity = getEntityFromDoc<Entity, EntityData, EntityDoc>(
            collectionName,
            doc,
            transformer,
            updateTransformer
          )
          if (newEntity) {
            setEntityAndLoading({ entity: newEntity, loading: false })
          }
        } catch (err) {
          captureError(err)
        }
      }
    )
  }, [
    collectionName,
    id,
    skip,
    transformer,
    updateTransformer,
    waitForDocWithId,
  ])

  return { entity, loading }
}

export function useFirebaseEntityOnce<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  id: string | undefined,
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>
) {
  const [{ entity, loading }, setEntityAndLoading] = useState<{
    entity: Entity | undefined
    loading: boolean
  }>({ entity: undefined, loading: true })

  useEffect(() => {
    if (!id) {
      return
    }
    getDoc(doc(firestore(), collectionName, id)).then(function onEntitySnapshot(
      doc
    ) {
      try {
        const newEntity = getEntityFromDoc<Entity, EntityData, EntityDoc>(
          collectionName,
          doc,
          transformer,
          updateTransformer
        )
        if (newEntity) {
          setEntityAndLoading({ entity: newEntity, loading: false })
        }
      } catch (err) {
        captureError(err)
      }
    })
  }, [collectionName, id, transformer, updateTransformer])

  return { entity, loading }
}

export async function getFirebaseEntity<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  id: string,
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>
) {
  return getDoc(doc(firestore(), collectionName, id)).then(
    function onEntitySnapshot(doc) {
      try {
        const newEntity = getEntityFromDoc<Entity, EntityData, EntityDoc>(
          collectionName,
          doc,
          transformer,
          updateTransformer
        )
        return newEntity
      } catch (err) {
        captureError(err)
      }
    }
  )
}

export async function getFirebaseEntityWithWhereClause<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  whereClause: [string | FieldPath, WhereFilterOp, any][],
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>,
  orderBys?: {
    fieldPath: string | FieldPath
    directionStr: OrderByDirection
  }[],
  limitValue?: number
) {
  const queryParams: QueryConstraint[] = []
  whereClause.forEach(clause => {
    queryParams.push(where(...clause))
  })
  orderBys?.forEach(orderByData => {
    queryParams.push(orderBy(orderByData.fieldPath, orderByData.directionStr))
  })
  if (limitValue) {
    queryParams.push(limit(limitValue))
  }

  const q = query(collection(firestore(), collectionName), ...queryParams)

  return getDocs(q).then(function onEntitySnapshot(querySnapshot) {
    try {
      const newEntities: (Entity | undefined)[] = []
      querySnapshot.forEach(doc => {
        newEntities.push(
          getEntityFromDoc<Entity, EntityData, EntityDoc>(
            collectionName,
            doc,
            transformer,
            updateTransformer
          )
        )
      })
      return newEntities.filter(entity => entity !== undefined)
    } catch (err) {
      captureError(err)
    }
  })
}

export function getEntityFromDoc<
  Entity extends ModelDefaults<EntityData>,
  EntityData,
  EntityDoc extends FirebaseDataDoc & ExpandedFirebaseDataDoc
>(
  collectionName: string,
  doc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>,
  transformer: (doc: EntityDoc) => EntityData | undefined,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>
): Entity | undefined {
  const docData = doc.data() as EntityDoc | undefined
  if (!docData) {
    return
  }
  const data = transformer(docData)

  if (data) {
    return {
      id: doc.id,
      ...(data as Partial<Entity>),
      created: getDateFromFirebaseTimestamp(
        docData.created,
        collectionName,
        doc.id
      ),
      modified: docData.modified
        ? getDateFromFirebaseTimestamp(docData.modified, collectionName, doc.id)
        : // @ts-ignore
        docData.lastUpdatedAt
        ? // @ts-ignore
          new Date(docData.lastUpdatedAt.seconds * 1000)
        : new Date(),
      createdBy: docData.createdBy ?? '',
      lastModifiedBy: docData.lastModifiedBy ?? '',
      update: (updates: Partial<EntityData>, userId: string | null) => {
        return updateEntity(
          collectionName,
          doc.id,
          updates,
          updateTransformer,
          userId
        )
      },
      delete: async (userId?: string | null) => {
        // Soft-delete for flows and tags
        if (collectionName === 'flows' && userId) {
          const updates = {
            status: PublishStatus.deleted,
          } as unknown as Partial<EntityData>
          await updateEntity(
            collectionName,
            doc.id,
            updates,
            updateTransformer,
            userId
          )
        } else if (collectionName.match(/^teams\/.*\/tags$/) && userId) {
          await updateEntity(
            collectionName,
            doc.id,
            {
              deleted: true,
            } as unknown as Partial<EntityData>,
            updateTransformer,
            userId
          )
        } else {
          await deleteEntity(collectionName, doc.id)
        }
      },
    } as Entity
  }
}

export async function createEntity<EntityDoc extends DocumentData>(
  collectionName: string,
  data: EntityDoc,
  userId: string | null
) {
  try {
    const docData: EntityDoc = {
      ...data,
      created: serverTimestamp(),
      modified: serverTimestamp(),
      createdBy: userId,
      lastModifiedBy: userId,
    }
    const res = await addDoc(collection(firestore(), collectionName), docData)
    return res.id
  } catch (err) {
    captureError(err)
  }
}

export async function updateEntity<EntityData, EntityDoc>(
  collectionName: string,
  id: string,
  updates: Partial<EntityData>,
  updateTransformer: (updates: Partial<EntityData>) => Partial<EntityDoc>,
  userId: string | null
) {
  let shouldSetModifiedDate = false
  if (collectionName === 'flows') {
    for (const key of Object.keys(updates)) {
      if (!['status', 'tagIds'].includes(key)) {
        shouldSetModifiedDate = true
        break
      }
    }
  } else {
    shouldSetModifiedDate = true
  }

  try {
    const docData: Partial<EntityDoc> = {
      ...(shouldSetModifiedDate
        ? {
            modified: serverTimestamp(),
          }
        : {}),
      lastModifiedBy: userId,
      ...updateTransformer(updates),
    }

    await updateDoc(
      doc(firestore(), collectionName, id),
      docData as DocumentData
    )
    return true
  } catch (err) {
    captureError(err)
    return false
  }
}

async function deleteEntity(collectionName: string, id: string) {
  // TODO we should change this to be a soft-delete
  try {
    await deleteDoc(doc(firestore(), collectionName, id))
    return true
  } catch (err) {
    captureError(err)
    return false
  }
}

export const awaitFirestoreDocCondition = <T>(
  collection: string,
  docId: string,
  condition: (data: DocumentData) => boolean,
  transform: (data: DocumentData) => T,
  timeoutSeconds: number
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      doc(getFirestore(), `${collection}/${docId}`),
      snapshot => {
        const data = snapshot.data()
        if (data && condition(data)) {
          unsubscribe()
          try {
            resolve(transform(data))
          } catch (e) {
            reject(e)
          }
        }
      }
    )
    setTimeout(async () => {
      unsubscribe()

      const snapshot = await getDocFromServer(
        doc(getFirestore(), `${collection}/${docId}`)
      )
      const data = snapshot.data()
      if (data && condition(data)) {
        resolve(transform(data))
      } else {
        reject(
          new Error(
            `Condition was not met, even after refetch collection:${collection} docId:${docId}`
          )
        )
      }
    }, timeoutSeconds * 1000)
  })
}
